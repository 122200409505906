




































































































// 订单头部筛选未完成
import { Component, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import { apiCalendarRecord } from '@/api/marketing/calendar'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        LsDialog,
        ExportData
    }
})
export default class CalendarRecord extends Vue {
    /** S Data **/
    $refs!: { paneTable: any }

    apiCalendarRecord = apiCalendarRecord

    SearchData = {
        nickname: '',
        sn: '',
        mobile: ''
    }

    pager = new RequestPaging()

    /** E Data **/

    /** S Method **/

    // 获取充值记录数据
    getRecord(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiCalendarRecord,
            params: {
                ...this.SearchData
            }
        })
    }

    // 重置搜索领取记录
    resetSearchData() {
        Object.keys(this.SearchData).map(key => {
            this.$set(this.SearchData, key, '')
        })
        this.getRecord()
    }

    /** E Method **/

    created() {
        this.getRecord()
    }
}
